import React, { Component } from "react";
import Helmet from "react-helmet"; 

import { Learning } from "../../components/learning/learning";
import Newsletter from "../../components/newsletter/newsletter";
import Section from "../../components/common/Section";
import Layout from "../../layouts";
import Hero from "../../components/common/Hero"; 
import presidentStyles from "./presidents.module.css";
import { Container, Row, Col } from "react-bootstrap";
import PresidentImageLink from "../../components/common/PresidentImageLink";
import SEO from "../../components/common/SEO"; 


class PresidentPage extends Component {
  render() {
    return (
      <Layout>      
        <Helmet bodyAttributes={{ class: "presidents-page" }} />
        <SEO post={{
          title: "US presidential performance across several different metrics",
          description: "Pick any president and see how the stock market, gross domestic product (GDP), and total jobs number have changed under their administration."
        }} />
        <Hero title='Track Your Favorite President'></Hero>
        <Container
          className={presidentStyles.instructions + " " + ["all-presidents"]}
        >
          Pick your favorite (or not so favorite) president and see their
          presidential performance across all the metrics we track. We currently
          track some or all metrics for all presidents starting from Harry
          Truman and continue to work on adding more presidents and metrics.
          <br />
          <br />
          Click on a president below to go to their specific presidential
          performance page.
          <Row>            
            <Col xs={6} md={2}  className={presidentStyles.presidentCell}>
              <PresidentImageLink size={125} name="Joe Biden" />                          
            </Col>
            <Col xs={6} md={2}  className={presidentStyles.presidentCell}>
              <PresidentImageLink size={125} name="Donald Trump" />                          
            </Col>
            <Col xs={6} md={2} className={presidentStyles.presidentCell}>
              <PresidentImageLink size={125} name="Barack Obama" />
            </Col>
            <Col xs={6} md={2} className={presidentStyles.presidentCell}>
              <PresidentImageLink size={125} name="George W Bush" />                          
            </Col>
            <Col xs={6} md={2} className={presidentStyles.presidentCell}>
              <PresidentImageLink size={125} name="Bill Clinton" />              
            </Col>
          </Row>
          <Row>
            <Col xs={6} md={2} className={presidentStyles.presidentCell}>
              <PresidentImageLink size={125} name="George H.W. Bush" />            
            </Col>
            <Col xs={6} md={2} className={presidentStyles.presidentCell}>
              <PresidentImageLink size={125} name="Ronald Reagan" />
            </Col>
            <Col xs={6} md={2} className={presidentStyles.presidentCell}>
              <PresidentImageLink size={125} name="Jimmy Carter" />
            </Col>           
            <Col xs={6} md={2} className={presidentStyles.presidentCell}>
              <PresidentImageLink  size={125} name="Gerald Ford" />              
            </Col>
            <Col xs={6} md={2} className={presidentStyles.presidentCell}>
              <PresidentImageLink  size={125} name="Richard Nixon" />              
            </Col>
          </Row>
          <Row>
            <Col  xs={6} md={2} className={presidentStyles.presidentCell}>
              <PresidentImageLink  size={125} name="Lyndon B. Johnson" />
            </Col>
            <Col xs={6} md={{ span: 3,  offset: 1 }} className={presidentStyles.presidentCell}>
              <PresidentImageLink  size={125} name="John F. Kennedy" />              
            </Col>
            <Col xs={6}  md={{ span: 3,  offset: 1 }} className={presidentStyles.presidentCell}>
              <PresidentImageLink size={125} name="Dwight D. Eisenhower" />              
            </Col>
            <Col xs={6} md={{ span: 3,  offset: 1 }} className={presidentStyles.presidentCell}>
              <PresidentImageLink  size={125} name="Harry S. Truman" />
            </Col>
          </Row>
        </Container>

         <Container className={'track-president'}>
          <Row>
            <Col>
              <Learning />
            </Col>
          </Row>
          <Row>
            <Col>
              <Section
                name="metrics_calculation"
                header="How We Calculate Our Presidential Performance Data"
              />
            </Col>
          </Row>
          <Row>
            <Col>
              <Newsletter />
            </Col>
          </Row>
        </Container>
      </Layout>
    );
  }
}

export default PresidentPage;

